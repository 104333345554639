import React, { useState, useEffect } from 'react';
import { Button, Tooltip, notification, Table, Space } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { getDetailsProfitProductsByDatesApi } from '../../../api/sale';
import { useMediaQuery } from 'react-responsive';

import './HistoryDetailsSalesProfit.scss';

export default function HistoryDetailsSalesProfit(props) {
    const { dateSince, dateTo, reloadSales, setReloadSales } = props;
    const [productsTale, setProductsTable] = useState([]);
    const [pagePagination, setPagePagination] = useState(1);
    const [limitPagination, setLimitPagination] = useState(null);
    const [totalPagination, setTotalPagination] = useState(null);

    const isMobile = useMediaQuery({ maxWidth: 425 });

    const token = getAccessTokenApi();

    useEffect(() => {
        getDetailsProfitProductsByDatesApi(token, pagePagination, formatDateSearch(dateSince), formatDateSearch(dateTo))
            .then((response) => {
                chargeSalesProducts(response);
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        setReloadSales(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagePagination, reloadSales]);

    function chargeSalesProducts(response) {
        if (response.success && response.detailProfit.length > 0) {
            const productsTmp = response.detailProfit;
            const products = [];
            for (let i = 0; productsTmp && i < productsTmp.length; i++) {
                products.push({
                    _id: productsTmp[i].product_id,
                    key: productsTmp[i].product_id,
                    product_name: productsTmp[i].product_name,
                    quantity: productsTmp[i].quantity,
                    profit: productsTmp[i].profit ? productsTmp[i].profit?.toFixed(2) : 0,
                });
            }

            setProductsTable(products);
            setLimitPagination(20);
            setTotalPagination(response.detailProfit.length);
        }
    }

    const columns = [
        { title: 'Producto', dataIndex: 'product_name', key: 'product_name' },
        {
            title: 'Cantidad',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (quantity) => <div style={{ textAlign: 'center' }}>{quantity}</div>,
        },
        {
            title: 'Ganancia',
            dataIndex: 'profit',
            key: 'profit',
            render: (profit) => <div style={{ textAlign: 'right' }}>{profit}</div>,
        },
        {
            title: 'Acciones',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, record) => (
                <div style={{ textAlign: 'center' }}>
                    <Space size="middle">
                        <Tooltip title="Reenviar documento">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<MoreOutlined />}
                                onClick={null}
                                style={{ float: 'right' }}
                                disabled={true}
                            />
                        </Tooltip>
                    </Space>
                </div>
            ),
        },
    ];

    function formatDateSearch(date) {
        const args = date.split('/');
        return args[2] + args[1] + args[0];
    }

    return (
        <div className="background_block">
            <h1>
                <b>Listado de productos vendidos</b>
            </h1>
            <Space size={20} direction="vertical">
                <div></div>
            </Space>
            <Table
                className="components-table-demo-nested"
                columns={columns}
                dataSource={productsTale}
                pagination={{
                    onChange: (pagePagination) => {
                        setPagePagination(pagePagination);
                    },
                    current: pagePagination,
                    pageSize: limitPagination,
                    total: totalPagination,
                }}
                scroll={isMobile ? { x: '100vw' } : null}
            />
        </div>
    );
}
