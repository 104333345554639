import React, { useState, useEffect } from 'react';
import { Row, Col, Button, notification, Divider, Space, Radio, DatePicker, Select, Tooltip, Input, Table } from 'antd';
import { PlusOutlined, MinusOutlined, DollarOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { generateSaleApi } from '../../../api/sale';
import {
    getSalesDocumentPdfApi,
    getSalesDocumentsByUserTypeSaleApi,
    getEnableBillPreviusMonthApi,
} from '../../../api/sale';
import moment from 'moment';
import dayjs from 'dayjs';
import { getAllPaymentTypesApi } from '../../../api/paymenttype';
import { onlyNumbersDecimal } from '../../../utils/generalValidation';

import jwtDecode from 'jwt-decode';

import './DetailsSales.scss';

export default function DetailsSales(props) {
    const {
        products,
        reloadProducts,
        clientSelected,
        reloadSale,
        setReloadSale,
        observation,
        isSellServices,
        services,
    } = props;
    const [currentSalesDocuments, setCurrentSalesDocuments] = useState([]);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [quotas, setQuotas] = useState([]);

    const [currentSalesDocumentSelected, setCurrentSalesDocumentSelected] = useState(null);
    const [dateNow, setDateNow] = useState('');
    const [typeMoney, setTypeMoney] = useState('1');
    const [paymentType, setPaymentType] = useState('1');
    const [affectionValue, setAffectionValue] = useState(0);
    const [unaffectionValue, setUnaffectionValue] = useState(0);
    const [igvValue, setIgvValue] = useState(0);
    const [disabledButton, setDisabledButton] = useState(false);
    const [enableModifyDateIssue, setEnableModifyDateIssue] = useState(false);

    const token = getAccessTokenApi();
    const user = jwtDecode(token);
    const dateFormat = 'DD-MM-YYYY';
    const { Option } = Select;

    useEffect(() => {
        getSalesDocumentsByUserTypeSaleApi(token, user.id)
            .then((response) => {
                if (response.currentsalesdocuments) {
                    setCurrentSalesDocuments(response.currentsalesdocuments);
                    for (let i = 0; i < response.currentsalesdocuments.length; i++) {
                        if (response.currentsalesdocuments[i].sales_document_code === '3') {
                            setCurrentSalesDocumentSelected(response.currentsalesdocuments[i]._id);
                        }
                    }
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        getAllPaymentTypesApi(token)
            .then((response) => {
                if (response.paymentTypes) {
                    setPaymentTypes(response.paymentTypes);
                } else {
                    notification['error']({ message: response.message });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });

        const now = new Date();
        const dateNow = convertDate(now);
        setDateNow(dateNow);
        setPaymentType('1');
    }, [token, user.id, reloadSale]);

    useEffect(() => {
        for (let i = 0; i < currentSalesDocuments.length; i++) {
            if (currentSalesDocumentSelected === currentSalesDocuments[i]._id) {
                getEnableBillPreviusMonthApi(token, user.id, currentSalesDocuments[i].sales_document_code)
                    .then((response) => {
                        setEnableModifyDateIssue(response);
                    })
                    .catch((err) => {
                        notification['error']({ message: err });
                    });
            }
        }

        const now = new Date();
        const dateNow = convertDate(now);
        setDateNow(dateNow);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSalesDocumentSelected]);

    useEffect(() => {
        if (products && products.length > 0) {
            var saleValue = 0;
            for (let i = 0; i < products.length; i++) {
                saleValue = saleValue + products[i].subtotal;
            }

            setAffectionValue(saleValue / (1 + 0.18));
            setIgvValue(saleValue - saleValue / (1 + 0.18));
            setUnaffectionValue(saleValue);
        }
    }, [products, reloadProducts]);

    useEffect(() => {
        if (services && services.length > 0) {
            var saleValue = 0;
            for (let i = 0; i < services.length; i++) {
                saleValue = saleValue + services[i].subtotal;
            }

            setAffectionValue(saleValue / (1 + 0.18));
            setIgvValue(saleValue - saleValue / (1 + 0.18));
            setUnaffectionValue(saleValue);
        }
    }, [services, reloadProducts]);

    async function generateSale() {
        // Validar datos
        /*if (!clientSelected._id) {
            notification['error']({ message: `No seleccionó cliente` });
            return;
        }*/
        let isNP = false;
        currentSalesDocuments.forEach((sd) => {
            if (currentSalesDocumentSelected === sd._id && sd.sales_document_code === '0') {
                isNP = true;
            }
        });
        if (
            !isNP &&
            unaffectionValue >= 700 &&
            (!clientSelected ||
                (!clientSelected.first_name && !clientSelected.entity_name) ||
                !clientSelected.document_number)
        ) {
            notification['error']({ message: `Venta mayor a 700, necesita ingresar datos de cliente` });
            return;
        }
        if (!currentSalesDocumentSelected) {
            notification['error']({ message: `No seleccionó Documento venta` });
            return;
        }
        if (!dateNow) {
            notification['error']({ message: `La venta tiene fecha de emisión` });
            return;
        }
        if (isSellServices) {
            if (services.length === 0) {
                notification['error']({ message: `No se agregó servicios` });
                return;
            }
        } else {
            if (products.length === 0) {
                notification['error']({ message: `No se agregó productos` });
                return;
            }
        }

        const quotasFormated = [];
        if (paymentType === '6') {
            let totalQuotas = 0;

            for (let i = 0; i < quotas.length; i++) {
                totalQuotas = totalQuotas + parseFloat(quotas[i].mount);
                if (!quotas[i].expire_date) {
                    notification['error']({ message: `Definir todas las fechas de las cuotas` });
                    return;
                }

                const newQuota = {
                    quota_number: quotas[i].quota_number,
                    mount: parseFloat(quotas[i].mount),
                    expire_date: quotas[i].expire_date.format('DD-MM-YYYY'),
                };
                quotasFormated.push(newQuota);
            }

            if (totalQuotas.toFixed(2) !== unaffectionValue.toFixed(2)) {
                notification['error']({ message: `El total de las cuotas es diferente al total de la venta` });
                return;
            }
        }

        for (let i = 0; i < products.length; i++) {
            if (user.stock_control) {
                if (!products[i].unit_portion || products[i].unit_portion === 1) {
                    if (products[i].price_sale_presentation <= 0) {
                        notification['error']({ message: `${products[i].name} tiene precio no válido` });
                        return;
                    }
                    if (products[i].quantity_presentation <= 0) {
                        notification['error']({ message: `${products[i].name} tiene cantidad no válida` });
                        return;
                    }
                } else {
                    if (products[i].price_sale_presentation <= 0 && products[i].price_sale_unit_portion <= 0) {
                        notification['error']({ message: `${products[i].name} tiene precio no válido` });
                        return;
                    }
                    if (products[i].quantity_presentation <= 0 && products[i].quantity_unit_portion <= 0) {
                        notification['error']({ message: `${products[i].name} tiene cantidad no válida` });
                        return;
                    }
                }

                if (products[i].stock_control) {
                    if (
                        (!products[i].stock_presentation_detail.stock_presentation ||
                            products[i].stock_presentation_detail.stock_presentation === 0) &&
                        (!products[i].stock_unit_portion_detail.stock_unit_portion ||
                            products[i].stock_unit_portion_detail.stock_unit_portion === 0)
                    ) {
                        notification['error']({
                            message: `${products[i].name} no tiene stock. Haga un ajuste de inventario`,
                        });
                        return;
                    }

                    const totalStock =
                        products[i].stock_presentation_detail.stock_presentation * products[i].unit_portion +
                        products[i].stock_unit_portion_detail.stock_unit_portion;
                    const totalQuantity =
                        parseFloat(products[i].quantity_presentation) * parseFloat(products[i].unit_portion) +
                        parseFloat(products[i].quantity_unit_portion);
                    if (totalStock < totalQuantity) {
                        notification['error']({ message: `${products[i].name} no tiene stock suficiente` });
                        return;
                    }
                }
            } else {
                if (products[i].price_sale <= 0) {
                    notification['error']({ message: `${products[i].name} tiene precio no válido` });
                    return;
                }
                if (products[i].quantity <= 0) {
                    notification['error']({ message: `${products[i].name} tiene cantidad no válida` });
                    return;
                }
            }
        }

        for (let i = 0; i < services.length; i++) {
            if (services[i].price_sale <= 0) {
                notification['error']({ message: `${services[i].name} tiene precio no válido` });
                return;
            }
            if (services[i].quantity <= 0) {
                notification['error']({ message: `${services[i].name} tiene cantidad no válida` });
                return;
            }
        }

        let paymentTypeCode;
        for (let i = 0; i < paymentTypes.length; i++) {
            if (paymentTypes[i].identifier === parseInt(paymentType)) {
                paymentTypeCode = paymentTypes[i].code;
                break;
            }
        }

        if (isSellServices) {
            const sale = {
                client: clientSelected._id,
                current_sales_document: currentSalesDocumentSelected,
                date: dateNow,
                type_money: typeMoney,
                type_pay: paymentTypeCode,
                observation: observation,
                affection_value: affectionValue,
                unaffection_value: unaffectionValue,
                igv_value: igvValue,
                services: services,
                type_print: user.type_print,
                is_sell_services: true,
                quotas: quotasFormated,
            };

            completeSale(sale);
        } else {
            const sale = {
                client: clientSelected._id,
                current_sales_document: currentSalesDocumentSelected,
                date: dateNow,
                type_money: typeMoney,
                type_pay: paymentTypeCode,
                observation: observation,
                affection_value: affectionValue,
                unaffection_value: unaffectionValue,
                igv_value: igvValue,
                products: products,
                type_print: user.type_print,
                is_sell_services: false,
                quotas: quotasFormated,
            };

            completeSale(sale);
        }
    }

    function completeSale(sale) {
        setDisabledButton(true);

        generateSaleApi(token, sale)
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                } else if (response.warning) {
                    notification['warning']({ message: response.message });
                } else {
                    notification['error']({ message: response.message });
                }

                if (response.document) {
                    getSalesDocumentPdfApi(token, response.document, user.company_id)
                        .then((response) => {
                            //const pdfBlob = new Blob([response], { type: 'application/pdf' });

                            window.open(response, '_blank');
                            setReloadSale(true);
                        })
                        .catch((err) => {
                            notification['error']({ message: err });
                        });
                }
                setDisabledButton(false);
            })
            .catch((err) => {
                setDisabledButton(false);
                notification['error']({ message: err });
            });
    }

    function onChangeTypeMoney(e) {
        setTypeMoney(e.target.value);
    }

    function onChangePaymentType(e) {
        setPaymentType(e);
        if (e === '6') {
            const quotaTmp = {
                quota_number: 1,
                mount: null,
                expire_date: null,
            };
            const quotasTmp = [];
            quotasTmp.push(quotaTmp);
            setQuotas(quotasTmp);
        } else {
            setQuotas([]);
        }
    }

    function onChangeSalesDocument(e) {
        setCurrentSalesDocumentSelected(e.target.value);
    }

    function onAddQuota() {
        const quotasTmp = [...quotas];
        const quotaTmp = {
            quota_number: quotas.length + 1,
            mount: null,
            expire_date: null,
        };
        quotasTmp.push(quotaTmp);
        setQuotas(quotasTmp);
    }

    function onRemoveQuota() {
        if (quotas.length > 1) {
            const quotasTmp = [...quotas];
            quotasTmp.pop();
            setQuotas(quotasTmp);
        }
    }

    const getValidDateFormat = (date) => {
        var newDate = moment(date, dateFormat);
        return date && newDate.isValid() ? newDate : '';
    };

    function convertDate(date) {
        var dateFormat = '';

        if (date.getDate() > 9) {
            dateFormat = dateFormat + date.getDate() + '-';
        } else {
            dateFormat = dateFormat + '0' + date.getDate() + '-';
        }
        if (date.getMonth() + 1 > 9) {
            dateFormat = dateFormat + (date.getMonth() + 1) + '-';
        } else {
            dateFormat = dateFormat + '0' + (date.getMonth() + 1) + '-';
        }
        dateFormat = dateFormat + date.getFullYear();

        /*dateFormat = dateFormat + ' ';
        if (date.getHours() > 9) {
            dateFormat = dateFormat + date.getHours() + ':';
        } else {
            dateFormat = dateFormat + '0' + date.getHours() + ':';
        }
        if (date.getMinutes() > 9) {
            dateFormat = dateFormat + date.getMinutes();
        } else {
            dateFormat = dateFormat + '0' + date.getMinutes();
        }*/

        return dateFormat;
    }

    const disabledDate = (current) => {
        return (
            current < dayjs().subtract(1, 'months').endOf('month').subtract(1, 'days') ||
            current > dayjs().startOf('day')
        );
    };

    const disabledDateQuota = (current) => {
        return current && current < moment().startOf('day');
    };

    const childrenPaymentType = [];
    for (let i = 0; i < paymentTypes.length; i++) {
        childrenPaymentType.push(<Option key={paymentTypes[i].identifier}>{paymentTypes[i].description}</Option>);
    }

    const onChangeInputQuota = (key, index) => (e) => {
        if (onlyNumbersDecimal(e.target.value)) {
            const newData = [...quotas];
            newData[index][key] = e.target.value;
            setQuotas(newData);
        }
    };

    function onChangeDateExpireQuota(key, index, e) {
        const newData = [...quotas];
        newData[index][key] = e;
        setQuotas(newData);
    }

    const columns_quotas = [
        {
            title: 'Cuota',
            dataIndex: 'quota_number',
            key: 'quota_number',
            render: (quota_number) => (
                <div style={{ textAlign: 'center' }}>
                    <b>{quota_number}</b>
                </div>
            ),
        },
        {
            title: 'Monto',
            dataIndex: 'mount',
            key: 'mount',
            render: (mount, record, index) => (
                <div style={{ textAlign: 'center' }}>
                    <Input
                        prefix={<DollarOutlined />}
                        style={{ width: '110px', textAlign: 'right' }}
                        value={mount}
                        onChange={onChangeInputQuota('mount', index)}
                        className="form-add_number"
                    />
                </div>
            ),
        },
        {
            title: 'Fecha Vencimiento',
            dataIndex: 'expire_date',
            key: 'expire_date',
            render: (expire_date, record, index) => (
                <div style={{ textAlign: 'center' }}>
                    <DatePicker
                        placeholder="Seleccionar"
                        format={dateFormat}
                        value={expire_date}
                        onChange={(e) => onChangeDateExpireQuota('expire_date', index, e)}
                        style={{ textAlign: 'right' }}
                        disabledDate={disabledDateQuota}
                    />
                </div>
            ),
        },
    ];

    return (
        <div>
            <Space size={20} direction="vertical" style={{ width: '100%' }}>
                <div className="background_block ">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <h1>
                                <b>Documento</b>
                            </h1>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="sales_row-document">
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Radio.Group onChange={onChangeSalesDocument} value={currentSalesDocumentSelected}>
                                <Row gutter={[16, 16]}>
                                    {currentSalesDocuments.map((salesDocument, index) => (
                                        <Col key={salesDocument._id} xs={24} sm={24} md={24} lg={24}>
                                            <Radio key={salesDocument._id} value={salesDocument._id}>
                                                {salesDocument.sales_document_name.replace('ELECTRÓNICA', '')}
                                            </Radio>
                                        </Col>
                                    ))}
                                </Row>
                            </Radio.Group>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>
                                {currentSalesDocuments.map((salesDocument, index) =>
                                    salesDocument._id === currentSalesDocumentSelected
                                        ? `${salesDocument.serie.toUpperCase()} - ${salesDocument.number + 1}`
                                        : ''
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Divider />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            {'Fecha de emisión:'}
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16}>
                            {enableModifyDateIssue ? (
                                <div style={{ textAlign: 'right' }}>
                                    <DatePicker
                                        placeholder="Seleccionar fecha emisión"
                                        format={dateFormat}
                                        value={getValidDateFormat(dateNow)}
                                        onChange={(e) => setDateNow(e.format(dateFormat))}
                                        style={{ textAlign: 'right' }}
                                        disabledDate={disabledDate}
                                    />
                                </div>
                            ) : (
                                <div style={{ textAlign: 'right', fontSize: 'large' }}>{dateNow}</div>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Space size={10} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            {'Forma de pago:'}
                        </Col>
                        <Col xs={16} sm={16} md={16} lg={16}>
                            <div style={{ textAlign: 'right' }}>
                                <Select
                                    placeholder="Forma de pago"
                                    onChange={onChangePaymentType}
                                    value={paymentType}
                                    style={{ width: '130px' }}
                                    className="form-add_select"
                                >
                                    {childrenPaymentType}
                                </Select>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Space size={10} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    {paymentType === '6' ? (
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <div style={{ textAlign: 'right' }}>
                                    <Row gutter={[16, 16]}>
                                        <Col xs={20} sm={20} md={20} lg={20}>
                                            <Tooltip title="Agregar cuota">
                                                <Button
                                                    type="primary"
                                                    shape="circle"
                                                    icon={<PlusOutlined />}
                                                    onClick={onAddQuota}
                                                    style={{ float: 'right' }}
                                                />
                                            </Tooltip>
                                        </Col>
                                        <Col xs={4} sm={4} md={4} lg={4}>
                                            <Tooltip title="Quitar cuota">
                                                <Button
                                                    danger
                                                    type="primary"
                                                    shape="circle"
                                                    icon={<MinusOutlined />}
                                                    onClick={onRemoveQuota}
                                                    style={{ float: 'right' }}
                                                />
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24}>
                                <Table columns={columns_quotas} dataSource={quotas} pagination={false} size="small" />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    <Row gutter={[24, 24]}>
                        <Space size={10} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={2} sm={2} md={2} lg={2}></Col>
                        <Col xs={22} sm={22} md={22} lg={22}>
                            <Radio.Group
                                onChange={onChangeTypeMoney}
                                value={typeMoney}
                                disabled={true}
                                style={{ float: 'right' }}
                            >
                                <Radio key={'0'} value={'1'}>
                                    {'Soles'}
                                </Radio>
                                <Radio key={'1'} value={'2'}>
                                    {'Dólares'}
                                </Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Divider />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={10} sm={10} md={10} lg={10}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>Valor</div>
                        </Col>
                        <Col xs={14} sm={14} md={14} lg={14}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>{affectionValue.toFixed(2)}</div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Space size={10} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={10} sm={10} md={10} lg={10}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>IGV (18%)</div>
                        </Col>
                        <Col xs={14} sm={14} md={14} lg={14}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>{igvValue.toFixed(2)}</div>
                        </Col>
                    </Row>
                    <Row gutter={[24, 24]}>
                        <Space size={10} direction="vertical">
                            <div></div>
                            <div></div>
                        </Space>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col xs={10} sm={10} md={10} lg={10}>
                            <div style={{ textAlign: 'right', fontSize: 'large' }}>Total</div>
                        </Col>
                        <Col xs={14} sm={14} md={14} lg={14}>
                            <div style={{ textAlign: 'right', fontSize: 'x-large' }}>{unaffectionValue.toFixed(2)}</div>
                        </Col>
                    </Row>
                </div>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Button
                            type="primary"
                            shape="round"
                            size="large"
                            onClick={generateSale}
                            disabled={disabledButton}
                            className="btn-submit"
                        >
                            Generar venta
                        </Button>
                    </Col>
                </Row>
            </Space>
        </div>
    );
}
