import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { Button, Tooltip, notification, Dropdown, Table, Space, Menu, Tag, Modal } from 'antd';
import { PrinterOutlined, SendOutlined, MoreOutlined } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import {
    getSalesByDatesApi,
    getSalesByDocumentSaleApi,
    getSalesByClientApi,
    rePrintSalesDocumentApi,
    reSendSalesDocumentApi,
    getSalesDocumentPdfApi,
    annularSaleApi,
} from '../../../api/sale';
import jwtDecode from 'jwt-decode';
import { useMediaQuery } from 'react-responsive';

import './HistoryDetailsSales.scss';

export default function HistoryDetailsSales(props) {
    const {
        searchType,
        dateSince,
        dateTo,
        serieSaleDocument,
        numberSaleDocument,
        clientSelected,
        reloadSales,
        setReloadSales,
    } = props;
    const [salesTale, setSalesTable] = useState([]);
    const [pagePagination, setPagePagination] = useState(1);
    const [limitPagination, setLimitPagination] = useState(null);
    const [totalPagination, setTotalPagination] = useState(null);
    const [visibleModal, setVisibleModal] = useState(false);
    const [record, setRecord] = useState(null);

    const isMobile = useMediaQuery({ maxWidth: 425 });

    const token = getAccessTokenApi();
    const user = jwtDecode(token);

    useEffect(() => {
        if (searchType === 1) {
            getSalesByDatesApi(token, pagePagination, formatDateSearch(dateSince), formatDateSearch(dateTo))
                .then((response) => {
                    chargeSalesDocuments(response);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } else if (searchType === 2) {
            getSalesByDocumentSaleApi(token, pagePagination, serieSaleDocument, numberSaleDocument)
                .then((response) => {
                    chargeSalesDocuments(response);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        } else if (searchType === 3) {
            getSalesByClientApi(token, pagePagination, clientSelected._id)
                .then((response) => {
                    chargeSalesDocuments(response);
                })
                .catch((err) => {
                    notification['error']({ message: err });
                });
        }
        setReloadSales(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchType, pagePagination, reloadSales]);

    useEffect(() => {
        setPagePagination(1);
    }, [searchType]);

    function chargeSalesDocuments(response) {
        if (response && response.sales) {
            const salesTmp = response.sales.docs;
            const sales = [];
            for (let i = 0; salesTmp && i < salesTmp.length; i++) {
                const products = [];
                for (let j = 0; salesTmp[i].products_detail && j < salesTmp[i].products_detail.length; j++) {
                    products.push({
                        key: salesTmp[i].products_detail[j].product_id,
                        name: salesTmp[i].products_detail[j].product_name,
                        quantity: salesTmp[i].products_detail[j].quantity,
                        price: parseFloat(salesTmp[i].products_detail[j].product_price).toFixed(2),
                        subtotal: parseFloat(salesTmp[i].products_detail[j].product_subtotal).toFixed(2),
                    });
                }

                sales.push({
                    _id: salesTmp[i]._id,
                    key: salesTmp[i]._id,
                    client: salesTmp[i].client_name,
                    document_code: salesTmp[i].sale_document_code,
                    document: salesTmp[i].serie + completeCeroLeft(salesTmp[i].number, 8),
                    date_issue: salesTmp[i].date_issue,
                    affection_value: salesTmp[i].affection_value.toFixed(2),
                    igv_value: salesTmp[i].igv_value.toFixed(2),
                    unaffection_value: salesTmp[i].unaffection_value.toFixed(2),
                    state: [salesTmp[i].state, salesTmp[i].hash],
                    products: products,
                });
            }

            setSalesTable(sales);
            setLimitPagination(response.sales.limit);
            setTotalPagination(response.sales.totalDocs);
        }
    }

    function onPrintSaleDocument(event) {
        rePrintSalesDocumentApi(token, event._id, '80')
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                } else if (response.warning) {
                    notification['warning']({ message: response.message });
                } else {
                    notification['error']({ message: response.message });
                }

                if (response.document) {
                    const documentName = response.document;
                    getSalesDocumentPdfApi(token, response.document, user.company_id)
                        .then((response) => {
                            //const pdfBlob = new Blob([response], { type: 'application/pdf' });
                            //saveAs(response, documentName + '.pdf');
                            window.open(response, '_blank');
                        })
                        .catch((err) => {
                            notification['error']({ message: err });
                        });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }

    function onReSendSaleDocument(e) {
        reSendSalesDocumentApi(token, e._id)
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                } else if (response.warning) {
                    notification['warning']({ message: response.message });
                } else {
                    notification['error']({ message: response.message });
                }

                if (response.document) {
                    const documentName = response.document;
                    getSalesDocumentPdfApi(token, response.document, user.company_id)
                        .then((response) => {
                            //saveAs(response, documentName + '.pdf');
                            window.open(response, '_blank');
                        })
                        .catch((err) => {
                            notification['error']({ message: err });
                        });
                }
                if (response.success) {
                    setReloadSales(true);
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }

    function onClickRePrintA4(event) {
        rePrintSalesDocumentApi(token, event._id, 'A4')
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                } else if (response.warning) {
                    notification['warning']({ message: response.message });
                } else {
                    notification['error']({ message: response.message });
                }

                if (response.document) {
                    const documentName = response.document;
                    getSalesDocumentPdfApi(token, response.document, user.company_id)
                        .then((response) => {
                            //saveAs(response, documentName + '-A4.pdf');
                            window.open(response, '_blank');
                        })
                        .catch((err) => {
                            notification['error']({ message: err });
                        });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }

    function onClickRePrintA5(event) {
        rePrintSalesDocumentApi(token, event._id, 'A5')
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                } else if (response.warning) {
                    notification['warning']({ message: response.message });
                } else {
                    notification['error']({ message: response.message });
                }

                if (response.document) {
                    const documentName = response.document;
                    getSalesDocumentPdfApi(token, response.document, user.company_id)
                        .then((response) => {
                            //saveAs(response, documentName + '-A5.pdf');
                            window.open(response, '_blank');
                        })
                        .catch((err) => {
                            notification['error']({ message: err });
                        });
                }
            })
            .catch((err) => {
                notification['error']({ message: err });
            });
    }

    const showModal = (record) => {
        setRecord(record);
        setVisibleModal(true);
    };

    const hideModal = () => {
        setVisibleModal(false);
    };

    const confirmAnnular = () => {
        onAnnularSale(record);
        setVisibleModal(false);
    };

    function onAnnularSale(event) {
        annularSaleApi(token, event._id)
            .then((response) => {
                if (response.success) {
                    notification['success']({ message: response.message });
                } else {
                    notification['error']({ message: response.message });
                }
                setReloadSales(true);
            })
            .catch((err) => {
                notification['error']({ message: err });
                setReloadSales(true);
            });
    }

    const columns = [
        { title: 'Cliente', dataIndex: 'client', key: 'client' },
        {
            title: 'Documento',
            dataIndex: 'document',
            key: 'document',
            render: (document) => <div style={{ textAlign: 'center' }}>{document}</div>,
        },
        {
            title: 'Fecha de emisión',
            dataIndex: 'date_issue',
            key: 'date_issue',
            render: (date_issue) => <div style={{ textAlign: 'center' }}>{date_issue}</div>,
        },
        {
            title: 'Venta',
            dataIndex: 'affection_value',
            key: 'affection_value',
            render: (affection_value) => <div style={{ textAlign: 'right' }}>{affection_value}</div>,
        },
        {
            title: 'IGV',
            dataIndex: 'igv_value',
            key: 'igv_value',
            render: (igv_value) => <div style={{ textAlign: 'right' }}>{igv_value}</div>,
        },
        {
            title: 'Total',
            dataIndex: 'unaffection_value',
            key: 'unaffection_value',
            render: (unaffection_value) => <div style={{ textAlign: 'right' }}>{unaffection_value}</div>,
        },
        {
            title: 'Estado',
            dataIndex: 'state',
            key: 'state',
            render: (state) => (
                <div style={{ textAlign: 'center' }}>
                    {state[0] === 'Activo' ? <Tag color="green">{state[0]}</Tag> : <Tag color="red">{state[0]}</Tag>}
                    {state[0] === 'Anulado' ? (
                        <Tag color="red">{state[0]}</Tag>
                    ) : state[1] ? (
                        <Tag color="green">Enviado</Tag>
                    ) : (
                        <Tag color="red">No enviado</Tag>
                    )}
                </div>
            ),
        },
        {
            title: 'Acciones',
            dataIndex: 'operation',
            key: 'operation',
            render: (_, record) => (
                <div style={{ textAlign: 'center' }}>
                    <Space size="middle">
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="1"
                                        onClick={() => onPrintSaleDocument(record)}
                                        disabled={record.state[0]?.includes('Anulado') ? true : false}
                                    >
                                        Reiprimir ticket
                                    </Menu.Item>
                                    <Menu.Item
                                        key="2"
                                        onClick={() => onClickRePrintA4(record)}
                                        disabled={record.state[0]?.includes('Anulado') ? true : false}
                                    >
                                        Reiprimir en A4
                                    </Menu.Item>
                                    <Menu.Item
                                        key="3"
                                        onClick={() => onClickRePrintA5(record)}
                                        disabled={record.state[0]?.includes('Anulado') ? true : false}
                                    >
                                        Reiprimir en A5
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Tooltip title="Reimprimir">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<PrinterOutlined />}
                                    onClick={null}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Dropdown>
                        <Tooltip title="Reenviar documento">
                            <Button
                                type="primary"
                                shape="circle"
                                icon={<SendOutlined />}
                                onClick={() => onReSendSaleDocument(record)}
                                style={{ float: 'right' }}
                                disabled={record.state[0]?.includes('Anulado') ? true : false}
                            />
                        </Tooltip>
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item
                                        key="1"
                                        onClick={() => showModal(record)}
                                        disabled={
                                            record.state[0] === 'Anulado' ||
                                            record.state[0] === 'Anulado con NC' ||
                                            record.document_code === '7'
                                                ? true
                                                : false
                                        }
                                    >
                                        Anular venta
                                    </Menu.Item>
                                    {/* <Divider style={{ margin: '5px' }} />
                                    <Menu.Item
                                        key="1"
                                        onClick={() => showModal(record)}
                                        disabled={
                                            record.state[0] === 'Anulado' ||
                                            record.state[0] === 'Anulado con NC' ||
                                            record.document_code === '7'
                                                ? true
                                                : false
                                        }
                                    >
                                        Descargar XML
                                    </Menu.Item>
                                    <Menu.Item
                                        key="1"
                                        onClick={() => showModal(record)}
                                        disabled={
                                            record.state[0] === 'Anulado' ||
                                            record.state[0] === 'Anulado con NC' ||
                                            record.document_code === '7'
                                                ? true
                                                : false
                                        }
                                    >
                                        Descargar CDR
                                    </Menu.Item> */}
                                </Menu>
                            }
                        >
                            <Tooltip title="Más opciones">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<MoreOutlined />}
                                    onClick={null}
                                    style={{ float: 'right' }}
                                />
                            </Tooltip>
                        </Dropdown>
                    </Space>
                </div>
            ),
        },
    ];

    const expandedRowRender = (e) => {
        const columns = [
            { title: 'Nombre de producto', dataIndex: 'name', key: 'name' },
            {
                title: 'Cantidad',
                dataIndex: 'quantity',
                key: 'quantity',
                render: (quantity) => <div style={{ textAlign: 'right' }}>{quantity}</div>,
            },
            {
                title: 'Precio',
                dataIndex: 'price',
                key: 'price',
                render: (price) => <div style={{ textAlign: 'right' }}>{price}</div>,
            },
            {
                title: 'Sub total',
                dataIndex: 'subtotal',
                key: 'subtotal',
                render: (subtotal) => <div style={{ textAlign: 'right' }}>{subtotal}</div>,
            },
        ];

        const products = e.products;

        return <Table columns={columns} dataSource={products} expandable={false} pagination={false} />;
    };

    function completeCeroLeft(value, length) {
        return value.toString().length < length ? completeCeroLeft('0' + value, length) : value;
    }

    function formatDateSearch(date) {
        const args = date.split('/');
        return args[2] + args[1] + args[0];
    }

    return (
        <div className="background_block">
            <h1>
                <b>Listado de ventas</b>
            </h1>
            <Space size={20} direction="vertical">
                <div></div>
            </Space>
            <Table
                className="components-table-demo-nested"
                columns={columns}
                expandable={{ expandedRowRender }}
                dataSource={salesTale}
                pagination={{
                    onChange: (pagePagination) => {
                        setPagePagination(pagePagination);
                    },
                    current: pagePagination,
                    pageSize: limitPagination,
                    total: totalPagination,
                }}
                summary={(pageData) => {
                    let totalAffection_value = 0;
                    let totalIgv_value = 0;
                    let totalUnaffection_value = 0;

                    pageData.forEach(({ affection_value, igv_value, unaffection_value, document_code, state }) => {
                        if (document_code === '7' || state[0] === 'Anulado') {
                            totalAffection_value -= parseFloat(affection_value);
                            totalIgv_value -= parseFloat(igv_value);
                            totalUnaffection_value -= parseFloat(unaffection_value);
                        } else {
                            totalAffection_value += parseFloat(affection_value);
                            totalIgv_value += parseFloat(igv_value);
                            totalUnaffection_value += parseFloat(unaffection_value);
                        }
                    });

                    return (
                        <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <div style={{ textAlign: 'right' }}>
                                        <b>Total</b>
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <div style={{ textAlign: 'right' }}>
                                        <b>{totalAffection_value.toFixed(2)}</b>
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <div style={{ textAlign: 'right' }}>
                                        <b>{totalIgv_value.toFixed(2)}</b>
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell>
                                    <div style={{ textAlign: 'right' }}>
                                        <b>{totalUnaffection_value.toFixed(2)}</b>
                                    </div>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                                <Table.Summary.Cell></Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
                scroll={isMobile ? { x: '100vw' } : null}
            />

            <Modal
                title="Anular venta"
                open={visibleModal}
                onOk={confirmAnnular}
                onCancel={hideModal}
                okText="Sí"
                cancelText="No"
            >
                <p>{`¿Está seguro de anular la venta?`}</p>
                <span>{`Documento: `}</span>
                &nbsp;&nbsp;
                <span>{`${record?.document ? record.document : ''}`}</span>
                <br />
                <span>{`Cliente: `}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>{`${record?.client ? record.client : ''}`}</span>
                <br />
                <span>{`Total: `}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>{`${record?.unaffection_value ? record.unaffection_value : ''} S/`}</span>
            </Modal>
        </div>
    );
}
